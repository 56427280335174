import * as Sentry from '@sentry/nuxt'
// @ts-ignore Initialize Sentry with custom configurations
Sentry.init({
  release: 'tarvikekeskus-frontend@1.0.0',
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: 'https://47c4a525f316318f8666ce5287123948@o455520.ingest.us.sentry.io/4508357806522368',
  environment: process.env.ENVIRONMENT,
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 0.2, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [process.env.FRENDS_API_URL + '/api/']
})
